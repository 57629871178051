import React from 'react';


export default function Subheader(){
    return(
        <div 
            style={{
                width: '100%',
                height: '50px',
                padding: '1rem',
                textAlign: 'center',
                background: '#44963B',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <p style={{color: '#fff', fontSize: '1rem'}}>Simule uma locação na opção
                <a href="https://alugue.nestrental.com.br/" style={{textDecoration: 'none', color: '#fff'}}> alugue online</a>
            </p>
        </div>
    )
}